import React from "react"
import styled from "styled-components"

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  border: 2px solid ${props => props.theme.colors.primary};
  outline: none;
`

const Video = ({ url, poster }) => {
  return (
    <StyledVideo controls loop poster={poster}>
      <source src={url.replace(/^http:\/\//i, "https://")} type="video/mp4" />
    </StyledVideo>
  )
}

export default Video
