import React from "react"

const OneDayAverage = ({ timestamps }) => {
  let total = 0

  timestamps.map(({ y }) => {
    return (total = total + y)
  })

  return Math.round(total / 24)
}

export default OneDayAverage
