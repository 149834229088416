import React, { useMemo } from "react"
import { useTable, useSortBy } from "react-table"
import styled from "styled-components"
import { connect } from "react-redux"
import DropdownArrow from "../images/dropdown-arrow.svg"
import goToPage from "../utils/tableLink"

import {
  keyReseller,
  officialRetailer,
  officialStore,
} from "../utils/storeTypes"

const TableRow = styled.tr`
  cursor: pointer;
  background-color: ${props =>
    props.isOdd
      ? props.theme.colors.gameCardBg
      : props.theme.colors.gameCardBgLight};

  .ref-link {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }

  :hover {
    .ref-link {
      color: ${props => props.theme.colors.primary};
      text-decoration: underline;
    }
  }
`

const TableData = styled.td`
  text-align: ${props => (props.num > 0 ? "right" : "left")};
  border: 1px solid #111021;
  font-size: 12px;
  padding: 8px !important;
  color: ${props => props.theme.colors.white};
`

const TableHeading = styled.th`
  border: 1px solid #111021;
  padding: 8px !important;
  background: #23222b;
  color: ${props => props.theme.colors.white};
  font-size: 12px;
  font-weight: 700;
`

const TableContainer = styled.div`
  @media (max-width: 420px) {
    overflow: hidden;
    overflow-x: scroll;
  }
`

const Table = ({ prices, currency }) => {
  const formatPrice = (price, currency, formatStyle) => {
    const formatedPrice = new Intl.NumberFormat(formatStyle, {
      style: "currency",
      currency: currency,
    }).format(price)
    return formatedPrice
  }

  let dataEUR = prices.filter(price => price.currency === "EUR")
  dataEUR.sort((a, b) => a.price - b.price)
  let dataUSD = prices.filter(price => price.currency === "USD")
  dataUSD.sort((a, b) => a.price - b.price)

  const data = useMemo(() => (currency ? dataEUR : dataUSD), [currency])

  const columns = useMemo(
    () => [
      {
        Header: "Store",
        accessor: "storeName",
        Cell: ({ row }) => {
          return <span className="ref-link">{row.original.storeName}</span>
        },
      },
      {
        Header: "Store Type",
        accessor: "storeType",
        Cell: ({ row }) => {
          return (
            <>
              {
                <span style={{ color: "#FFCD56" }}>
                  {keyReseller.includes(row.original.storeName) &&
                    "Key Reseller"}
                </span>
              }
              {
                <span style={{ color: "#C742FE" }}>
                  {officialStore.includes(row.original.storeName) &&
                    "Official Store"}
                </span>
              }
              {
                <span style={{ color: "#59D4FA" }}>
                  {officialRetailer.includes(row.original.storeName) &&
                    "Official Retailer"}
                </span>
              }
            </>
          )
        },
      },
      {
        Header: "Current Price",
        accessor: "price",
        Cell: ({ row }) => {
          return currency ? (
            <span style={{ color: "#8FED6E" }}>
              {formatPrice(row.original.price, "EUR", "de-DE")}
            </span>
          ) : (
            <span style={{ color: "#8FED6E" }}>
              {formatPrice(row.original.price, "USD", "en-US")}
            </span>
          )
        },
      },
    ],
    [currency]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy)

  return (
    <TableContainer>
      <table {...getTableProps()} style={{ marginBottom: 8 }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHeading
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            alt="Arrow icon pointing up."
                            src={DropdownArrow}
                            style={{ marginBottom: 0 }}
                          />
                        ) : (
                          <img
                            alt="Arrow icon pointing down."
                            src={DropdownArrow}
                            style={{
                              marginBottom: 0,
                              transform: "rotate(180deg)",
                            }}
                          />
                        )
                      ) : (
                        <img
                          src={DropdownArrow}
                          style={{ marginBottom: 0 }}
                          alt="Arrow icon pointing up."
                        />
                      )}
                    </span>
                  </div>
                </TableHeading>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} isOdd={Boolean(index % 2)}>
                {row.cells.map((cell, index) => {
                  return (
                    <TableData
                      color={index}
                      num={index}
                      {...cell.getCellProps()}
                      onClick={() => goToPage(cell.row.original.refLink)}
                    >
                      {cell.render("Cell")}
                    </TableData>
                  )
                })}
              </TableRow>
            )
          })}
        </tbody>
      </table>
    </TableContainer>
  )
}

const mapStateToProps = state => ({
  currency: state.currency,
})

export default connect(mapStateToProps, null)(Table)
