import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import { connect } from "react-redux"
import moment from "moment"
import Img from "gatsby-image"
import axios from "axios"
import Select from "react-select"
import {
  CardTags,
  CardTag,
  Tag,
  GridContainer,
  Card as GridCard,
  CardLink,
} from "../components/styled/grid.js"
import { NoData } from "../components/styled/noData"
import {
  LoadMoreButton,
  LoadMoreContainer,
} from "../components/styled/loadMore"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { customStylesGamePage } from "../styles/customSelectStyles"
import Table from "../components/table"
import Video from "../components/video"
import CustomVideo from "../components/Iframe"
import SteamIcon from "../images/steam-icon.svg"
import WebsiteIcon from "../images/website-icon.svg"
import { Container } from "../components/styled/container"
import PlayerChart from "../components/highcharts/index"
import OneDayAverage from "../components/oneDayAverage/index"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const GameCard = styled.div`
  padding-bottom: 32px;
  padding-top: 88px;
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 360px 1fr;
  background-color: ${props => props.theme.colors.gameCardBg};

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`

const CardContent = styled.div`
  padding: 24px;
`

const CardStats = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, auto);
  text-align: center;
  margin-bottom: 8px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
`

const GameCardTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.gameCardTitle};
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
`

const GameCardData = styled.h3`
  color: ${props => props.color};
  margin-bottom: 0;
`

const SearchInput = styled.div`
  width: 264px;
  margin-bottom: 16px;

  @media (max-width: 576px) {
    width: 100%;
  }
`

const GameInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  padding-bottom: 32px;
  padding-top: 16px;

  @media (max-width: 769px) {
    grid-template-columns: 1fr;
  }
`

const GameInfo = styled.div`
  h4 {
    text-transform: uppercase;
    margin-bottom: 6px;
    font-size: 14px;
    color: #989898;
  }

  p {
    text-transform: capitalize;
    margin-bottom: 0;
  }

  a {
    color: white;
  }
`

const GameInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 8px;
`

const GameTemplate = ({ data, currency }) => {
  const [limit, setLimit] = useState(3)

  const loadMoreNews = () => {
    setLimit(limit + 3)
  }

  let options = []
  data.allStrapiGame.edges.map(({ node }) => {
    let obj = {
      value: node.slug,
      label: node.title,
    }

    return options.push(obj)
  })

  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="6517054732" />
        <Container main w="100%">
          <SEO
            title={`${data.strapiGame.title} - FGCharts`}
            description={data.strapiGame.gameData?.description}
          />
          <GameCard>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {data.strapiGame.gameData ? (
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    marginBottom: 8,
                  }}
                  href={`https://store.steampowered.com/app/${data.strapiGame.appId}`}
                >
                  <img
                    src={SteamIcon}
                    alt="Steam Logo"
                    style={{ marginBottom: 0, marginRight: 8 }}
                  />
                  Steam Page
                </a>
              ) : (
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    marginBottom: 8,
                  }}
                  href="https://toughlovearena.com/"
                  target="_blank"
                >
                  <img
                    src={WebsiteIcon}
                    alt="Website icon"
                    style={{ marginBottom: 0, marginRight: 8 }}
                  />
                  Official Website
                </a>
              )}

              <SearchInput>
                <Select
                  closeMenuOnSelect={true}
                  isClearable={false}
                  onChange={e => navigate(`/games/${e.value}`)}
                  isMulti={false}
                  options={options}
                  styles={customStylesGamePage}
                  placeholder={"Search Games..."}
                  noOptionsMessage={() => "Game not found"}
                />
              </SearchInput>
            </div>
            <h3 style={{ marginBottom: 8 }}>{data.strapiGame.title}</h3>
            <Card>
              <Img
                style={{ height: "100% !important" }}
                fluid={data.strapiGame.banner.childImageSharp.fluid}
              />
              <CardContent>
                <CardStats>
                  <div>
                    <GameCardTitle>Current Players</GameCardTitle>
                    <GameCardData color="#FFCD56">
                      {data.strapiGame.currentPlayersCount}
                    </GameCardData>
                  </div>
                  <div>
                    <GameCardTitle>One Hour Ago</GameCardTitle>
                    <GameCardData color="#F19954">
                      {data.strapiGame.playersOneHourAgo}
                    </GameCardData>
                  </div>
                  <div>
                    <GameCardTitle>24-hour average</GameCardTitle>
                    <GameCardData color="#8FED6E">
                      <OneDayAverage
                        timestamps={data.strapiGame.timestamps.slice(-24)}
                        font={26}
                      />
                    </GameCardData>
                  </div>
                  <div>
                    <GameCardTitle>all-time Peak</GameCardTitle>
                    <GameCardData color="#F84F68">
                      {data.strapiGame.AllTimePeakManual
                        ? data.strapiGame.AllTimePeakManual
                        : data.strapiGame.allTimePeak}
                    </GameCardData>
                  </div>
                </CardStats>
                <CardTags center>
                  {data.strapiGame.tags.map((tag, index) => {
                    return (
                      <CardTag
                        red
                        key={index}
                        right={data.strapiGame.tags.length > 1}
                      >
                        <Tag white>{tag}</Tag>
                      </CardTag>
                    )
                  })}
                </CardTags>
              </CardContent>
            </Card>
          </GameCard>
          <PlayerChart timestamps={data.strapiGame.timestamps} />
          {data.strapiGame.title === "Tough Love Arena" ? (
            <GameInformation>
              <CustomVideo url="https://www.youtube.com/embed/aNp8tEeyDuo" />

              <GameInfoWrapper>
                <GameInfo style={{ gridColumn: "span 2" }}>
                  <h4>Game Description</h4>
                  <p style={{ textTransform: "none" }}>
                    Tough Love Arena is a web-based, indie fighting game with
                    rollback netcode made by two people - Paul and Amy.
                  </p>
                </GameInfo>
                <GameInfo>
                  <h4>Release Date</h4>
                  <p>January 1st, 2021</p>
                </GameInfo>
                <GameInfo>
                  <h4>Official Website</h4>
                  <a href="https://toughlovearena.com" target="_blank">
                    Tough Love Arena
                  </a>
                </GameInfo>
              </GameInfoWrapper>
            </GameInformation>
          ) : (
            <GameInformation>
              {data.strapiGame.trailer !== "empty" || "" ? (
                <CustomVideo url={data.strapiGame.trailer} />
              ) : (
                <Video
                  url={data.strapiGame.gameData?.trailer}
                  poster={data.strapiGame.gameData?.trailerThumbnail}
                />
              )}

              <GameInfoWrapper>
                <GameInfo style={{ gridColumn: "span 2" }}>
                  <h4>Game Description</h4>
                  <p style={{ textTransform: "none" }}>
                    {data.strapiGame.gameData?.description}
                  </p>
                </GameInfo>
                <GameInfo>
                  <h4>Release Date</h4>
                  <p>{data.strapiGame.gameData?.releaseDate}</p>
                </GameInfo>
                <GameInfo>
                  <h4>Official Website</h4>
                  <a href={data.strapiGame.gameData?.officalWebsite}>
                    {data.strapiGame.title}
                  </a>
                </GameInfo>
                <GameInfo>
                  <h4>Developer</h4>
                  <p>{data.strapiGame.gameData?.developers}</p>
                </GameInfo>
                <GameInfo>
                  <h4>Publisher</h4>
                  <p>{data.strapiGame.gameData?.publishers}</p>
                </GameInfo>

                <GameInfo>
                  <h4>Total Steam Reviews</h4>
                  {data.strapiGame.gameData && (
                    <a
                      href={`https://store.steampowered.com/app/${data.strapiGame.appId}/TEKKEN_7/#app_reviews_hash`}
                    >
                      {data.strapiGame.gameData.steamTotalReviews}
                    </a>
                  )}
                </GameInfo>
                <GameInfo>
                  <h4>Positive / Negative Reviews</h4>
                  <p>
                    <span style={{ color: "#8FED6E" }}>
                      {data.strapiGame.gameData?.steamPositiveReviews}
                    </span>
                    <span style={{ margin: "0 6px" }}>-</span>
                    <span style={{ color: "#F84F68" }}>
                      {data.strapiGame.gameData?.steamNegativeReviews}
                    </span>
                  </p>
                </GameInfo>
              </GameInfoWrapper>
            </GameInformation>
          )}

          <div id="prices" style={{ paddingBottom: 32 }}>
            <h4 style={{ marginBottom: 16, textTransform: "uppercase" }}>
              Game stores
            </h4>

            {data.strapiGame.affiliates ? (
              <div>
                <Table prices={data.strapiGame.affiliates} />
                <small style={{ color: "#FBFBFC", opacity: 0.6 }}>
                  {data.strapiPrices.text}
                </small>
              </div>
            ) : (
              <NoData style={{ gridColumn: "span 3" }}>
                No prices for this game yet!
              </NoData>
            )}
          </div>
          <div>
            <h4 style={{ marginBottom: 16, textTransform: "uppercase" }}>
              Latest news for {data.strapiGame.title}
            </h4>

            <GridContainer>
              {data.strapiGame.post_news.length > 0 ? (
                data.strapiGame.post_news.slice(0, limit).map((news, index) => {
                  return (
                    <CardLink to={`/news/${news.slug}`} key={index}>
                      <GridCard>
                        <div>
                          <Img
                            style={{ height: 160 }}
                            fluid={data.strapiGame.banner.childImageSharp.fluid}
                          />
                        </div>
                        <div style={{ padding: "7px 16px 16px 16px" }}>
                          <h3
                            style={{
                              marginBottom: 8,
                            }}
                          >
                            {news.title}
                          </h3>
                          <p style={{ marginBottom: 8, fontSize: 14 }}>
                            {news.description}
                          </p>
                          <div>
                            <CardTags>
                              {data.strapiGame.post_news.map(game => {
                                return game.tags
                                  .slice(0, 3)
                                  .map((tag, index) => {
                                    return (
                                      <CardTag red key={index} right>
                                        <Tag>{tag}</Tag>
                                      </CardTag>
                                    )
                                  })
                              })}
                              <CardTag>
                                {data.strapiGame.post_news.map(
                                  (game, index) => {
                                    return game.tags.length - 3 > 0 ? (
                                      <Tag key={index}>+</Tag>
                                    ) : undefined
                                  }
                                )}
                              </CardTag>
                            </CardTags>
                            <div
                              style={{
                                marginTop: 16,
                              }}
                            >
                              <small>{`${news.author} - ${moment(
                                news.createdAt
                              ).format("LL")}`}</small>
                            </div>
                          </div>
                        </div>
                      </GridCard>
                    </CardLink>
                  )
                })
              ) : (
                <NoData style={{ gridColumn: "span 3" }}>
                  There are currently no news for this game.
                </NoData>
              )}
            </GridContainer>

            {data.strapiGame.post_news.length > 3 &&
            limit < data.strapiGame.post_news.length ? (
              <LoadMoreContainer>
                <LoadMoreButton onClick={loadMoreNews}>
                  Show More
                </LoadMoreButton>
              </LoadMoreContainer>
            ) : undefined}
          </div>
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}

export const query = graphql`
  query GameTemplate($slug: String!) {
    allStrapiGame {
      edges {
        node {
          slug
          title
        }
      }
    }
    strapiPrices {
      text
    }
    strapiGame(slug: { eq: $slug }) {
      title
      tags
      trailer
      appId
      AllTimePeakManual
      currentPlayersCount
      playersOneHourAgo
      allTimePeak
      timestamps {
        x
        y
      }
      gameData {
        description
        officalWebsite
        developers
        publishers
        steamTotalReviews
        steamPositiveReviews
        steamNegativeReviews
        steamReviewDesc
        releaseDate
        trailer
        trailerThumbnail
      }
      affiliates {
        price
        discountPrice
        storeName
        refLink
        currency
      }
      post_news {
        title
        description
        author
        createdAt
        id
        slug
        tags
      }
      banner {
        childImageSharp {
          fluid(maxWidth: 360, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  currency: state.currency,
})

export default connect(mapStateToProps, null)(GameTemplate)
