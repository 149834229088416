import Highcharts from "highcharts"
import moment from "moment"

const setChartOptions = data => {
  const options = {
    lang: {
      noData: "We don't have any statistics for this game yet.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#FBFBFC",
      },
    },
    chart: {
      zoomType: "x",
      backgroundColor: "transparent",
      resetZoomButton: {
        theme: {
          r: 0,
          fill: "#F84F68",
          stroke: "#111021",
          style: {
            color: "#FBFBFC",
            fontWeight: "bold",
            textTransform: "uppercase",
          },
          states: {
            hover: {
              fill: "#F84F68",
            },
          },
        },
      },
    },
    title: {
      text: "FGCharts Player Statistics",
      align: "left",
      style: {
        fontFamily: "Oswald",
        color: "#FBFBFC",
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    xAxis: {
      type: "datetime",
      lineColor: "#FBFBFC",
      lineWidth: 0,

      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%e.%b", this.value)
        },
        style: {
          color: "rgba(251, 251, 252, .7)",
        },
      },
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: "#FBFBFC",
      },
      itemHoverStyle: {
        color: "#FBFBFC",
      },
    },
    credits: {
      enabled: true,
      href: "https://fgcharts.com",
      text: "fgcharts.com",
    },
    plotOptions: {
      series: {
        color: "#4960EA",
        events: {
          legendItemClick: function () {
            return false
          },
        },
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "rgba(251, 251, 252, .7)",
        },
      },
    },

    tooltip: {
      followPointer: true,
      backgroundColor: "#121122",
      borderColor: "#4A60EA",
      style: {
        color: "#FBFBFC",
      },
      padding: 12,
      formatter: function () {
        return `<span style='font-size: 12px;'>${moment(this.x).format(
          "dddd, MMM D, HH:mm"
        )}</span><br/> <span style='font-size: 14px;font-weight: bold'>Players: ${
          this.y
        }</span>`
      },
    },
    series: [
      {
        name: "Players",
        data,
        color: "#F19954",
      },
    ],
  }

  return options
}

export default setChartOptions
