export const officialRetailer = [
  "Gamesplanet",
  "DLGamer",
  "Green Man Gaming US",
  "GamersGate.com",
  "Fanatical",
  "Green Man Gaming",
  "Voidu B.V.",
  "GameBillet",
]
export const keyReseller = [
  "Eneba.com",
  "G2A",
  "MMOGA Ltd. US",
  "Kinguin",
  "CDKeys.com",
]
export const officialStore = ["Steam", "Humble Bundle"]
