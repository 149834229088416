import React from "react"
import styled from "styled-components"
import Iframe from "react-iframe"

const IframeVideo = styled(Iframe)`
  width: 100%;
  height: 315px;
  border: 2px solid ${props => props.theme.colors.primary};
  outline: none;
`

const CustomVideo = ({ url }) => {
  return <IframeVideo url={url} />
}

export default CustomVideo
