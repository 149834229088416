import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import NoDataToDisplay from "highcharts/modules/no-data-to-display" // No data module

import setChartOptions from "./chartOptions"

// Use module
if (typeof Highcharts === "object") {
  NoDataToDisplay(Highcharts)
}

const PlayersChart = ({ timestamps }) => {
  const options = setChartOptions(timestamps)

  return (
    <div style={{ paddingBottom: 32, paddingTop: 16 }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default PlayersChart
